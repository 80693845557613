import { WorkflowTemplate } from '../models/WorkflowTemplate';
import { WorkflowTemplateListFilters } from '../models/WorkflowTemplateListFilters';
import RequestService from './RequestService';
import { CultureCode } from '../models/CultureCode';
import { ExportedWorkflowMetadata } from '../models/ExportedWorkflowMetadata';
import { WorkflowTemplateEdit } from '../models/WorkflowTemplateEdit';
import { WorkflowTemplateFilter } from '../models/WorkflowTemplateFilter';

type ApiWorkflowTemplate = {
    id: string;
    name: string;
    description: string;
    isPublished: boolean;
    lastPublishedDate?: string;
    creationDate: string;
    lastModifiedDate: string;
    thumbnailImageUrl: string;
    cultureCode: CultureCode;
    knowledgeCenterUrl?: string;
    token: string;
    goalIds: string[];
    levelId: string;
    packIds?: string[];
    channelIds?: string[];
    tags?: string[];
};

const toWorkflowTemplate = (t: ApiWorkflowTemplate) => ({
    ...t,
    lastPublishedDate: t.lastPublishedDate
        ? new Date(t.lastPublishedDate)
        : undefined,
    creationDate: new Date(t.creationDate),
    lastModifiedDate: new Date(t.lastModifiedDate)
});

const WorkflowTemplateService = {
    async getTemplateList(
        filters: WorkflowTemplateListFilters
    ): Promise<WorkflowTemplate[]> {
        const {
            goalIds: goals,
            packIds: packs,
            levelIds: levels,
            channelIds: channels,
            ...rest
        } = filters;

        const filterRequested = {
            ...rest,
            ...(goals &&
                goals.length && {
                    goalIds: goals.map(goal => goal.id)
                }),
            ...(channels &&
                channels.length && {
                    channelIds: channels.map(channel => channel.id)
                }),
            ...(packs &&
                packs.length && {
                    packIds: packs.map(pack => pack.id)
                }),
            ...(levels &&
                levels.length && {
                    levelIds: levels.map(level => level.id)
                })
        };
        const templates = await RequestService.cnFetch<ApiWorkflowTemplate[]>(
            '/workflow-template/',
            {},
            filterRequested
        );
        return templates.map(toWorkflowTemplate);
    },

    async getTemplate(id: string): Promise<WorkflowTemplate> {
        const template = await RequestService.cnFetch<ApiWorkflowTemplate>(
            `/workflow-template/${id}`
        );
        return toWorkflowTemplate(template);
    },

    getExportedWorkflowMetadata(
        token: string
    ): Promise<ExportedWorkflowMetadata> {
        return RequestService.cnFetch(
            `/workflow-template/exported-workflow-metadata/${token}`
        );
    },

    createTemplate(template: WorkflowTemplateEdit): Promise<WorkflowTemplate> {
        return RequestService.cnFetch('/workflow-template/', {
            method: 'POST',
            body: JSON.stringify(template)
        });
    },

    async updateTemplate(
        id: string,
        template: WorkflowTemplateEdit
    ): Promise<WorkflowTemplate> {
        const result = await RequestService.cnFetch<ApiWorkflowTemplate>(
            `/workflow-template/${id}`,
            {
                method: 'PATCH',
                body: JSON.stringify(template)
            }
        );
        return toWorkflowTemplate(result);
    },

    setPublished(id: string, isPublished: boolean): Promise<WorkflowTemplate> {
        return RequestService.cnFetch(`/workflow-template/${id}`, {
            method: 'PATCH',
            body: JSON.stringify({ isPublished })
        });
    },

    delete(templateId: string): Promise<void> {
        return RequestService.cnFetch(`/workflow-template/${templateId}`, {
            method: 'DELETE'
        });
    },
    async getTemplateFilters(): Promise<WorkflowTemplateFilter> {
        const templateFilters =
            await RequestService.cnFetch<WorkflowTemplateFilter>(
                '/workflow-template/filters'
            );
        return templateFilters;
    }
};

export default WorkflowTemplateService;

import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions
} from '@mui/material';
import { WorkflowTemplate } from '../../models/WorkflowTemplate';
import { Button } from '@connectif/ui-components';

export type WorkflowTemplateDeleteConfirmation = Pick<
    WorkflowTemplate,
    'id' | 'name'
>;

type Props = {
    open: boolean;
    template: WorkflowTemplateDeleteConfirmation;
    onClose: () => void;
    onDelete: (template: WorkflowTemplateDeleteConfirmation) => void;
};

export default function DeleteTemplateConfirmationDialog({
    open,
    template,
    onClose,
    onDelete
}: Props): JSX.Element {
    return (
        <Dialog open={open} onClose={onClose} maxWidth='md'>
            <DialogTitle>Warning</DialogTitle>
            <DialogContent>
                <p>
                    Are you sure you want to delete the template{' '}
                    <b>{template.name}</b>?
                </p>
                <p>
                    Deleting this template can&apos;t be undone what means you
                    won&apos;t be able to restore it again.
                </p>
            </DialogContent>
            <DialogActions>
                <Button
                    iconId='check'
                    variant='contained'
                    onClick={() => onDelete(template)}
                    text='Delete'
                />
                <Button
                    iconId='close'
                    variant='text'
                    onClick={onClose}
                    text='Cancel'
                />
            </DialogActions>
        </Dialog>
    );
}

import React, { PropsWithChildren, useEffect, useRef } from 'react';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import './ExpandableElement.css';

type Props = PropsWithChildren<{
    maxHeightCollapsed: number;
    noExpandable?: boolean;
    forceExpanded?: boolean;
    forceShowExpanded?: boolean;
    onChangeExpanded?: (expanded: boolean) => void;
    onSetIsExpandable?: (isExplandable: boolean) => void;
}>;

const useStyles = makeStyles(() =>
    createStyles({
        expandable: {},
        fadeout: {
            position: 'relative',
            width: '100%'
        },
        arrow: {
            position: 'absolute',
            width: '100%',
            color: '#a4a8ab',
            cursor: 'pointer'
        }
    })
);

export default function ExpandableElement({
    maxHeightCollapsed,
    noExpandable,
    forceExpanded,
    forceShowExpanded,
    onChangeExpanded,
    onSetIsExpandable,
    children
}: Props): JSX.Element {
    const classes = useStyles();
    const myContainer = useRef<HTMLDivElement>(null);

    const [isExpandable, setIsExpandable] = React.useState<boolean>(false);
    const [isExpanded, setIsExpanded] = React.useState<boolean>(false);
    const [minHeight, setMinHeight] = React.useState<number>(0);
    const [marginBottomValue, setMarginBottomValue] = React.useState<number>(0);
    const [hasExpandableValueSetting, setHasExpandableValueSetting] =
        React.useState<boolean>(false);

    useEffect(() => {
        const elementHeight =
            myContainer != null && myContainer.current !== null
                ? myContainer.current.offsetHeight
                : 0;

        setMinHeight(maxHeightCollapsed - 10);
        const isExpandableValue = elementHeight >= maxHeightCollapsed;
        if (onSetIsExpandable && isExpandableValue) {
            onSetIsExpandable(isExpandableValue);
        }
        setIsExpandable(isExpandableValue);
    }, [maxHeightCollapsed, onSetIsExpandable]);

    useEffect(() => {
        const elementHeight =
            myContainer != null && myContainer.current !== null
                ? myContainer.current.offsetHeight
                : 0;
        setMarginBottomValue(maxHeightCollapsed - elementHeight);
        setHasExpandableValueSetting(true);
    }, [hasExpandableValueSetting, marginBottomValue, maxHeightCollapsed]);

    const changeExpanded = (expanded: boolean) => {
        setIsExpanded(expanded);
        if (onChangeExpanded) {
            onChangeExpanded(expanded);
        }
    };

    return (
        <div
            className={`expandable-container ${
                isExpandable ? classes.expandable : ''
            }`}
            style={{
                minHeight:
                    !isExpandable || hasExpandableValueSetting
                        ? `${maxHeightCollapsed}px`
                        : `${maxHeightCollapsed - 1}px`,
                maxHeight:
                    !isExpanded && !forceExpanded
                        ? `${maxHeightCollapsed}px`
                        : '500px',
                position: 'relative'
            }}
        >
            <div
                ref={myContainer}
                className={'wrapper-container'}
                style={{
                    minHeight: `${minHeight}px`,
                    maxHeight:
                        !isExpanded && !forceExpanded
                            ? `${maxHeightCollapsed}px`
                            : '1000px',

                    overflow: 'hidden'
                }}
            >
                {children}
            </div>

            {((isExpandable && !forceExpanded) || forceShowExpanded) && (
                <div
                    className={`fadeout-element ${
                        !isExpanded && !forceExpanded ? 'fadeout' : undefined
                    } ${classes.fadeout}`}
                    style={{
                        position:
                            !isExpanded && !forceExpanded
                                ? 'absolute'
                                : 'relative',
                        height: !isExpanded && !noExpandable ? '36px' : '18px',
                        bottom: 0,
                        backgroundImage: `linear-gradient(
                            rgba(255, 255, 255, 0) 0%,
                            rgba(255, 255, 255, 1) 75%
                        )`
                    }}
                >
                    {!isExpanded &&
                        !forceExpanded &&
                        ((!noExpandable && isExpandable) ||
                            forceShowExpanded) && (
                            <KeyboardArrowDownIcon
                                className={classes.arrow}
                                style={{
                                    bottom:
                                        (isExpanded ||
                                            !forceExpanded ||
                                            (isExpandable && !isExpanded)) &&
                                        hasExpandableValueSetting &&
                                        marginBottomValue >= 0
                                            ? `${-1 + -1 * marginBottomValue}px`
                                            : undefined
                                }}
                                onClick={() => changeExpanded(true)}
                            />
                        )}
                    {(isExpanded || forceExpanded) &&
                        ((!noExpandable && isExpandable) ||
                            forceShowExpanded) && (
                            <KeyboardArrowUpIcon
                                className={classes.arrow}
                                style={{
                                    bottom: '-10px'
                                }}
                                onClick={() => changeExpanded(false)}
                            />
                        )}
                </div>
            )}
        </div>
    );
}

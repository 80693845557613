import React, { useCallback, useEffect, useRef } from 'react';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import './ExpandableElement.css';
import { Grid } from '@mui/material';
import ChipsSelector from './ChipsSelector';
import './TagListExpandable.css';

type Props = {
    maxHeightCollapsed: number;
    forceExpanded?: boolean;
    tags: string[];
    onChangeExpanded?: (expanded: boolean) => void;
    onSetIsExpandable?: (isExplandable: boolean) => void;
};

const useStyles = makeStyles(() =>
    createStyles({
        expandable: {},
        fadeout: {
            position: 'relative',
            width: '100%'
        },
        arrow: {
            position: 'absolute',
            width: '100%',
            color: '#a4a8ab',
            cursor: 'pointer'
        }
    })
);

export default function TagListExpandable({
    maxHeightCollapsed,
    forceExpanded,
    tags,
    onChangeExpanded,
    onSetIsExpandable
}: Props): JSX.Element {
    const classes = useStyles();
    const myContainer = useRef<HTMLDivElement>(null);

    const [isExpandable, setIsExpandable] = React.useState<boolean>(false);
    const [isInitialize, setIsInitialize] = React.useState<boolean>(false);
    const [isExpanded, setIsExpanded] = React.useState<boolean>(false);
    const [minHeight, setMinHeight] = React.useState<number>(0);
    const [marginBottomValue, setMarginBottomValue] = React.useState<number>(0);
    const [hasExpandableValueSetting, setHasExpandableValueSetting] =
        React.useState<boolean>(false);
    const [tagsCollapsed, setTagsCollapsed] = React.useState<string[]>(tags);
    const [tagsHidden, setTagsHidden] = React.useState<number>(0);
    const [elementWrapperHeight, setElementWrapperHeight] =
        React.useState<number>(0);

    useEffect(() => {
        const elementHeight =
            myContainer != null && myContainer.current !== null
                ? myContainer.current.offsetHeight
                : 0;

        setMinHeight(maxHeightCollapsed - 10);
        const isExpandableValue = elementHeight >= maxHeightCollapsed;
        if (onSetIsExpandable && isExpandableValue) {
            onSetIsExpandable(isExpandableValue);
        }
        if (isExpandableValue) {
            setIsExpandable(true);
        }
    }, [maxHeightCollapsed, onSetIsExpandable]);

    useEffect(() => {
        if (forceExpanded === false) {
            setIsExpanded(false);
        }
    }, [forceExpanded]);

    const getLastTagRendered = useCallback((): string => {
        if (
            !myContainer ||
            !myContainer.current ||
            !myContainer.current.firstChild ||
            !myContainer.current.firstChild.firstChild ||
            !myContainer.current.firstChild.firstChild.firstChild ||
            !myContainer.current.firstChild.firstChild.firstChild.lastChild ||
            !myContainer.current.firstChild.firstChild.firstChild.lastChild
                .firstChild ||
            !myContainer.current.firstChild.firstChild.firstChild.lastChild
                .firstChild.firstChild ||
            !myContainer.current.firstChild.firstChild.firstChild.lastChild
                .firstChild.firstChild.textContent
        ) {
            return '';
        }

        return myContainer.current.firstChild.firstChild.firstChild.lastChild
            .firstChild.firstChild.textContent;
    }, []);

    const hasFinishedToRenderNewTags = useCallback((): boolean => {
        const lastTagRendered = getLastTagRendered();
        return lastTagRendered === tagsCollapsed[tagsCollapsed.length - 1];
    }, [getLastTagRendered, tagsCollapsed]);

    const calculateTags = useCallback(
        (elementHeight: number) => {
            if (elementHeight > maxHeightCollapsed) {
                setElementWrapperHeight(elementHeight);
                const lineHeight = 28;
                const numLinesExpected = Math.floor(
                    maxHeightCollapsed / lineHeight
                );
                const isFirstCall =
                    !tagsCollapsed[tagsCollapsed.length - 1].startsWith('+ ');
                const numLinesCurrent = Math.floor(elementHeight / lineHeight);
                const numTagsToRemove = numLinesCurrent - numLinesExpected;
                const elementsToRemove =
                    isFirstCall && numTagsToRemove > 0
                        ? numTagsToRemove
                        : numTagsToRemove + 1;

                const tagsToShowHidden = tagsCollapsed.slice(
                    0,
                    -1 * elementsToRemove
                );
                const numberTagsHidden =
                    tagsHidden +
                    (numTagsToRemove > 0 ? numTagsToRemove : elementsToRemove);

                tagsToShowHidden.push(`+ ${numberTagsHidden}`);
                setTagsCollapsed(tagsToShowHidden);
                setTagsHidden(numberTagsHidden);
                return;
            }
            setIsInitialize(true);
        },
        [maxHeightCollapsed, tagsCollapsed, tagsHidden]
    );

    const reloadTags = useCallback(() => {
        setTimeout(() => {
            const elementHeight =
                myContainer != null &&
                myContainer.current !== null &&
                myContainer.current.firstElementChild != null
                    ? (myContainer.current.firstElementChild as HTMLElement)
                          .offsetHeight
                    : 0;

            if (
                elementWrapperHeight !== elementHeight ||
                hasFinishedToRenderNewTags()
            ) {
                calculateTags(elementHeight);
            } else {
                reloadTags();
            }
        }, 100);
    }, [calculateTags, elementWrapperHeight, hasFinishedToRenderNewTags]);

    useEffect(() => {
        const elementHeight =
            myContainer != null &&
            myContainer.current !== null &&
            myContainer.current.firstElementChild != null
                ? (myContainer.current.firstElementChild as HTMLElement)
                      .offsetHeight
                : 0;

        if (
            elementHeight > maxHeightCollapsed &&
            (elementWrapperHeight !== elementHeight ||
                hasFinishedToRenderNewTags())
        ) {
            calculateTags(elementHeight);
        } else if (elementHeight > maxHeightCollapsed) {
            reloadTags();
        } else {
            setIsInitialize(true);
        }
    }, [
        maxHeightCollapsed,
        elementWrapperHeight,
        calculateTags,
        reloadTags,
        hasFinishedToRenderNewTags
    ]);

    useEffect(() => {
        const elementHeight =
            myContainer != null && myContainer.current !== null
                ? myContainer.current.offsetHeight
                : 0;

        setMarginBottomValue(maxHeightCollapsed - elementHeight);
        setHasExpandableValueSetting(true);
    }, [hasExpandableValueSetting, marginBottomValue, maxHeightCollapsed]);

    const changeExpanded = (expanded: boolean) => {
        setIsExpanded(expanded);
        if (onChangeExpanded) {
            onChangeExpanded(expanded);
        }
    };

    const onChipClicked = (chip: string): void => {
        if (tagsCollapsed[tagsCollapsed.length - 1] === chip) {
            changeExpanded(true);
        }
    };

    return (
        <div
            className={`tag-list-expandable-container ${
                isExpandable ? classes.expandable : ''
            }`}
            style={{
                minHeight:
                    !isExpandable || isInitialize
                        ? `${maxHeightCollapsed}px`
                        : `${maxHeightCollapsed - 1}px`,
                maxHeight:
                    !isExpanded && !forceExpanded
                        ? `${maxHeightCollapsed}px`
                        : '500px',
                marginBottom:
                    (isExpanded ||
                        !forceExpanded ||
                        (isExpandable && !isExpanded)) &&
                    hasExpandableValueSetting &&
                    marginBottomValue >= 0
                        ? `${marginBottomValue}px`
                        : undefined,
                position: 'relative',
                visibility: !isInitialize ? 'hidden' : undefined
            }}
        >
            <div
                ref={myContainer}
                className={'wrapper-container'}
                style={{
                    minHeight: `${minHeight}px`,
                    maxHeight:
                        !isExpanded && !forceExpanded
                            ? `${maxHeightCollapsed}px`
                            : '1000px',

                    overflow: 'hidden'
                }}
            >
                <Grid item xs={12}>
                    {tags && isExpandable && !isExpanded && !forceExpanded && (
                        <ChipsSelector
                            classChipContainer={'chip-container'}
                            readonly={true}
                            columnsInput={12}
                            chips={tagsCollapsed}
                            onChipClicked={chip => onChipClicked(chip)}
                        ></ChipsSelector>
                    )}
                    {tags && (!isExpandable || isExpanded || forceExpanded) && (
                        <ChipsSelector
                            readonly={true}
                            columnsInput={12}
                            chips={tags}
                        ></ChipsSelector>
                    )}
                </Grid>
            </div>
        </div>
    );
}

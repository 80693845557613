import withStyles from '@mui/styles/withStyles';
import { ToggleButtonGroup } from '@mui/material';

const FilterToggleButtonGroup = withStyles(theme => ({
    root: {
        gap: 10
    },
    grouped: {
        borderRadius: 2,
        color: theme.palette.primary.main,
        borderColor: 'white',
        backgroundColor: 'white'
    },
    groupedHorizontal: {
        '&:not(:first-child)': {
            marginLeft: 0,
            borderLeft: '1px solid white'
        }
    }
}))(ToggleButtonGroup);

export default FilterToggleButtonGroup;

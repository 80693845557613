import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { TextField } from '@mui/material';

type Props = {
    value?: string;
    placeholder: string;
    onChange: (searchText: string) => void;
};

export default function SearchBox({
    value,
    placeholder,
    onChange
}: Props): JSX.Element {
    return (
        <TextField
            className={'form-control search-box'}
            placeholder={placeholder}
            value={value}
            fullWidth
            variant='filled'
            size='small'
            hiddenLabel
            onChange={e => onChange(e.target.value)}
            InputProps={{
                endAdornment: <SearchIcon color='primary' />,
                style: { fontSize: '11px' }
            }}
        />
    );
}

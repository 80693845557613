import React, { useEffect, useState } from 'react';
import { Divider, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ToggleButton } from '@mui/material';
import { WorkflowTemplateListFilters } from '../../models/WorkflowTemplateListFilters';
import { allCultureCodes, CultureCode } from '../../models/CultureCode';
import { Status } from '../../models/Status';
import FilterToggleButtonGroup from '../../components/FilterToggleButtonGroup';
import { publishedColor, draftColor } from '../../Theme';

type Props = {
    filters: WorkflowTemplateListFilters;
    onChangeFilters: (filters: WorkflowTemplateListFilters) => void;
};

const useStyles = makeStyles(() => ({
    label: {
        lineHeight: '32px',
        marginRight: 10,
        minWidth: 70,
        display: 'inline-block'
    }
}));

function Dot({ color }: { color: string }) {
    return (
        <div
            style={{
                backgroundColor: color,
                width: 8,
                height: 8,
                borderRadius: '50%',
                marginRight: 5
            }}
        />
    );
}

export default function TemplateListFilters({
    filters,
    onChangeFilters
}: Props): JSX.Element {
    const [cultureCodes, setCultureCodes] = useState<readonly CultureCode[]>(
        filters.cultureCodes ?? []
    );
    const [statuses, setStatuses] = useState<readonly Status[]>([]);

    useEffect(() => {
        if (!filters.cultureCodes?.length) {
            setCultureCodes([]);
        }
        if (filters.isPublished === undefined) {
            setStatuses([]);
        }
    }, [filters]);

    const onChangeCultureCodes = (cultureCodes: CultureCode[]) => {
        setCultureCodes(cultureCodes);
        if (
            cultureCodes.length === 0 ||
            cultureCodes.length === allCultureCodes.length
        ) {
            const { cultureCodes, ...rest } = filters;
            onChangeFilters(rest);
        } else {
            onChangeFilters({ ...filters, cultureCodes });
        }
    };

    const onChangeStatuses = (statuses: Status[]) => {
        setStatuses(statuses);
        const isPublished =
            statuses.length === 1 ? statuses[0] === 'published' : undefined;
        onChangeFilters({ ...filters, isPublished });
    };

    const classes = useStyles();
    return (
        <Grid container spacing={1}>
            <Grid item xs={9}>
                <Grid container spacing={1}>
                    <Grid item>
                        <Typography component='span' className={classes.label}>
                            Language:
                        </Typography>
                        <FilterToggleButtonGroup
                            value={cultureCodes}
                            onChange={(e, cultureCodes) =>
                                onChangeCultureCodes(cultureCodes)
                            }
                        >
                            <ToggleButton value='es'>Spanish</ToggleButton>
                            <ToggleButton value='en'>English</ToggleButton>
                        </FilterToggleButtonGroup>
                    </Grid>
                    <Divider
                        orientation='vertical'
                        flexItem
                        style={{ margin: '4px 10px', height: 32 }}
                    />
                    <Grid item>
                        <Typography component='span' className={classes.label}>
                            Status:
                        </Typography>
                        <FilterToggleButtonGroup
                            value={statuses}
                            onChange={(e, statuses) =>
                                onChangeStatuses(statuses)
                            }
                        >
                            <ToggleButton value='published'>
                                <Dot color={publishedColor} />
                                Published
                            </ToggleButton>
                            <ToggleButton value='draft'>
                                <Dot color={draftColor} />
                                Draft
                            </ToggleButton>
                        </FilterToggleButtonGroup>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { WorkflowTemplateFilterListData } from '../models/WorkflowTemplateFilterListData';
import { WorkflowTemplateFilter } from '../models/WorkflowTemplateFilter';
import WorkflowTemplateService from '../services/WorkflowTemplateService';

type EffectData = {
    filtersListData: WorkflowTemplateFilterListData[];
    filtersData: WorkflowTemplateFilter;
    loading: boolean;
    setFiltersListData?: Dispatch<SetStateAction<EffectData>>;
};

const createListFilters = (
    filters: WorkflowTemplateFilter
): WorkflowTemplateFilterListData[] => {
    const listFilters: WorkflowTemplateFilterListData[] = [];
    if (filters.goalIds && filters.goalIds.length) {
        listFilters.push({
            name: 'goal',
            checked: false,
            open: true,
            children: filters.goalIds.map(goal => ({
                id: goal.id,
                translation: goal.translation,
                checked: false
            }))
        });
    }
    if (filters.packIds && filters.packIds.length) {
        listFilters.push({
            name: 'pack',
            checked: false,
            open: true,
            children: filters.packIds.map(pack => ({
                id: pack.id,
                translation: pack.translation,
                checked: false
            }))
        });
    }
    if (filters.channelIds && filters.channelIds.length) {
        listFilters.push({
            name: 'channel',
            checked: false,
            open: true,
            children: filters.channelIds.map(channel => ({
                id: channel.id,
                translation: channel.translation,
                checked: false
            }))
        });
    }
    if (filters.levelIds && filters.levelIds.length) {
        listFilters.push({
            name: 'level',
            checked: false,
            open: true,
            children: filters.levelIds.map(level => ({
                id: level.id,
                translation: level.translation,
                checked: false
            }))
        });
    }
    if (filters.tags && filters.tags.length) {
        listFilters.push({
            name: 'tags',
            checked: false,
            open: true,
            children: filters.tags.map(tag => ({
                id: tag,
                translation: tag,
                checked: false
            }))
        });
    }
    return listFilters;
};

const getTemplateFiltersListData = async (): Promise<EffectData> => {
    const filtersData = await WorkflowTemplateService.getTemplateFilters();

    const filtersListData = createListFilters(filtersData);

    return {
        filtersListData,
        filtersData,
        loading: false
    };
};

export const useWorkflowTemplateFilterData = (): EffectData => {
    const [state, setState] = useState<EffectData>({
        filtersListData: [],
        filtersData: {
            goalIds: [],
            channelIds: [],
            levelIds: [],
            packIds: [],
            tags: []
        },
        loading: true
    });

    useEffect(() => {
        void getTemplateFiltersListData().then(effectData => {
            effectData.setFiltersListData = setState;
            setState(effectData);
        });
    }, []);

    return state;
};

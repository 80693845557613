import React, { useCallback, useEffect, useRef } from 'react';
import Tooltip from '@mui/material/Tooltip';
import makeStyles from '@mui/styles/makeStyles';

type Props = {
    text: string;
    parentWidth: number;
    font?: number;
};

const useStylesBootstrap = makeStyles(() => ({
    tooltip: {
        fontSize: '12px'
    }
}));

export default function MiddleEllipsis({
    text,
    parentWidth,
    font
}: Props): JSX.Element {
    const classes = useStylesBootstrap();
    const span = useRef<HTMLSpanElement>(null);

    const [textToShow, setTextToShow] = React.useState<string>(text);
    const [spanWidth, setSpanWidth] = React.useState<number>(0);

    const calculateTextToShow = useCallback(() => {
        const spanElement = span ? span.current : undefined;
        if (spanElement && parentWidth) {
            if (spanWidth === 0) {
                setSpanWidth(spanElement.offsetWidth);
            } else if (spanElement.offsetWidth > parentWidth) {
                const separator = ' ... ';
                const textSeparated = textToShow.split(separator);
                const hasTextDivided = textSeparated.length > 1;
                const characterWidth =
                    spanElement.offsetWidth / textToShow.length;
                const numberCharactersRemove =
                    (spanElement.offsetWidth - parentWidth) / characterWidth;

                const charactersRemoveEachPart = Math.floor(
                    numberCharactersRemove / 2
                );

                const middleTextNumber = Math.floor(textToShow.length / 2) - 1;

                const removeInitialCharacter =
                    charactersRemoveEachPart === 0 && hasTextDivided
                        ? 1
                        : charactersRemoveEachPart;

                let initialText = '';

                if (!hasTextDivided) {
                    initialText = textToShow.substr(
                        0,
                        middleTextNumber - 1 - removeInitialCharacter
                    );
                } else {
                    initialText = textSeparated[0].substr(
                        0,
                        textSeparated[0].length - removeInitialCharacter
                    );
                }

                let finalText = '';

                if (!hasTextDivided) {
                    finalText = textToShow.substr(
                        middleTextNumber -
                            1 +
                            ' ... '.length +
                            charactersRemoveEachPart
                    );
                } else {
                    finalText = textSeparated[1].substr(
                        charactersRemoveEachPart
                    );
                }

                setTextToShow(`${initialText} ... ${finalText}`);
                setSpanWidth(spanElement.offsetWidth);
            }
        }
    }, [parentWidth, spanWidth, textToShow]);

    useEffect(() => {
        calculateTextToShow();
    }, [calculateTextToShow]);

    return (
        <>
            {textToShow === text && (
                <span
                    ref={span}
                    style={{
                        whiteSpace: 'nowrap',
                        display: 'inline-block',
                        fontSize: (font || 14) + 'px'
                    }}
                >
                    {textToShow}
                </span>
            )}

            {textToShow !== text && (
                <Tooltip title={text} classes={classes}>
                    <span
                        ref={span}
                        style={{
                            whiteSpace: 'nowrap',
                            display: 'inline-block',
                            fontSize: (font || 14) + 'px'
                        }}
                    >
                        {textToShow}
                    </span>
                </Tooltip>
            )}
        </>
    );
}

import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Fade } from '@mui/material';
import './Spinner.css';

interface Props {
    visible: boolean;
}

export default function Spinner(props: Props): JSX.Element {
    return (
        <Fade in={props.visible}>
            <div
                className={`backdrop cn-spinner-container ${
                    props.visible ? 'visible' : ''
                }`}
            >
                <CircularProgress />
            </div>
        </Fade>
    );
}

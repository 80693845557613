import { createTheme, SimplePaletteColorOptions } from '@mui/material/styles';

const palette: SimplePaletteColorOptions = {
    main: '#5E91EB',
    light: '#B8D0F2'
};

const borderColor = '#e5e5e5';

export const publishedColor = '#AED581';
export const draftColor = '#979797';
export const border = `1px solid ${borderColor}`;

const theme = createTheme({
    typography: {
        fontFamily: 'Source Sans Pro',
        fontSize: 12,
        h1: {
            fontFamily: 'Fira Sans',
            fontSize: '18px',
            color: '#47535d',
            fontWeight: 700,
            lineHeight: '36px'
        },
        h3: {
            fontFamily: 'Fira Sans',
            fontSize: '16px',
            color: '#47535d',
            fontWeight: 700,
            marginBottom: 8
        },
        h4: {
            fontFamily: 'Source Sans Pro',
            fontSize: '16px',
            color: '#47535d',
            fontWeight: 600,
            marginBottom: 8
        },
        body1: {
            fontSize: '14px',
            fontWeight: 400
        },
        body2: {
            fontSize: '14px',
            fontWeight: 600
        },
        caption: {
            fontSize: '18px',
            color: '#47535d',
            fontWeight: 400
        }
    },
    palette: {
        primary: palette,
        info: {
            main: palette.main,
            light: '#ECF2FB'
        },
        error: {
            main: '#FF3D00'
        }
    },
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    borderRadius: 0,
                    '& .MuiFilledInput-root': {
                        backgroundColor: '#F9FAFB'
                    },
                    '& .MuiFilledInput-underline:after': {
                        borderWidth: 1
                    }
                }
            }
        },
        MuiToggleButton: {
            styleOverrides: {
                root: {
                    padding: '4px 12px',
                    fontSize: 14,
                    textTransform: 'none',
                    border: `1px solid ${palette.light}`,
                    color: palette.main
                }
            }
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    paddingTop: 24,
                    paddingBottom: 12,
                    fontSize: '18px',
                    fontWeight: 700
                }
            }
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    paddingTop: 0,
                    fontFamily: 'Source Sans Pro',
                    fontSize: '16px',
                    fontWeight: 400
                }
            }
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    fontFamily: 'Source Sans Pro',
                    justifyContent: 'space-between',
                    paddingLeft: 24,
                    paddingRight: 24,
                    paddingBottom: 24
                }
            }
        }
    }
});

export default theme;

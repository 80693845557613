import React from 'react';
import {
    BrowserRouter as Router,
    Navigate,
    Outlet,
    Route,
    Routes
} from 'react-router-dom';
import './App.css';
import Login from './pages/login/Login';
import TemplateCreate from './pages/template/TemplateCreate';
import TemplateEdit from './pages/template/TemplateEdit';
import TemplateList from './pages/template/TemplateList';
import { SnackbarProvider } from 'notistack';
import makeStyles from '@mui/styles/makeStyles';
import AuthService from './services/AuthService';

const useStyles = makeStyles(() => ({
    variantSuccess: {
        borderLeft: '4px solid var(--pale-olive-green)',
        color: 'var(--gunmetal) !important',
        backgroundColor: 'white !important',
        '& .MuiSvgIcon-root': {
            color: 'var(--pale-olive-green) !important'
        }
    },
    variantError: {
        borderLeft: '4px solid var(--red-orange)',
        color: 'var(--gunmetal) !important',
        backgroundColor: 'white !important',
        '& .MuiSvgIcon-root': {
            color: 'var(--red-orange) !important'
        }
    },
    variantInfo: {
        borderLeft: '4px solid var(--soft-blue)',
        color: 'var(--gunmetal) !important',
        backgroundColor: 'white !important',
        '& .MuiSvgIcon-root': {
            color: 'var(--soft-blue) !important'
        }
    },
    variantWarning: {
        borderLeft: '4px solid var(--tangerine)',
        color: 'var(--gunmetal) !important',
        backgroundColor: 'white !important',
        '& .MuiSvgIcon-root': {
            color: 'var(--tangerine) !important'
        }
    }
}));

function App(): JSX.Element {
    const classes = useStyles();
    return (
        <SnackbarProvider
            maxSnack={3}
            autoHideDuration={5000}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            classes={classes}
        >
            <Router>
                <Routes>
                    <Route path='/login' element={<Login />} />
                    <Route element={<RequireAuth />}>
                        <Route
                            path='/template/list'
                            element={<TemplateList />}
                        />
                        <Route
                            path='/template/create'
                            element={<TemplateCreate />}
                        />
                        <Route
                            path='/template/:id/edit'
                            element={<TemplateEdit />}
                        />
                    </Route>
                    <Route
                        path='*'
                        element={<Navigate replace to='/template/list' />}
                    />
                </Routes>
            </Router>
        </SnackbarProvider>
    );
}

const RequireAuth = () =>
    AuthService.isUserLoggedIn() ? (
        <Outlet />
    ) : (
        <Navigate replace to='/login' />
    );

export default App;

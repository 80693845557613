import React, { useCallback, useEffect, useRef } from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import ClearIcon from '@mui/icons-material/Clear';
import { Grid, GridSize, TextField } from '@mui/material';
import './ChipsSelector.css';
import SuggestionPanel from './SuggestionPanel';

type Props = {
    readonly: boolean;
    onChipsChange?: (chips: string[]) => void;
    onChipClicked?: (chip: string) => void;
    columnsInput?: number;
    chips: string[];
    suggestedChips?: string[];
    classChipContainer?: string;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            justifyContent: 'flex-start',
            flexWrap: 'wrap',
            listStyle: 'none',
            padding: theme.spacing(0.5),
            margin: 0,
            paddingLeft: '0px',
            paddingTop: '3px'
        },
        chip: {
            margin: '6px 6px 0 0',
            borderRadius: '5px',
            fontSize: '12px'
        }
    })
);

export default function ChipsSelector({
    readonly,
    columnsInput,
    chips,
    classChipContainer,
    suggestedChips,
    onChipsChange,
    onChipClicked
}: Props): JSX.Element {
    const classes = useStyles();
    const [chipData, setChipData] = React.useState<string[]>(
        chips && chips.length ? chips : []
    );
    const [text, setText] = React.useState<string>('');
    const textField = useRef(null);

    const [showSuggestionPanel, setShowSuggestionPanel] =
        React.useState<boolean>(false);

    const reloadChips = useCallback(() => {
        setChipData(chips);
    }, [chips]);

    useEffect(() => {
        reloadChips();
    }, [reloadChips]);

    const handleDelete = (chipToDelete: string) => () => {
        if (!readonly && onChipsChange) {
            const newChips = chipData.filter(chip => chip !== chipToDelete);
            setChipData(newChips);
            onChipsChange(newChips);
        }
    };

    const onChangeText = (textChanged: string) => {
        if (!readonly) {
            setText(textChanged);
            if (textChanged.length >= 2) {
                setShowSuggestionPanel(true);
            } else {
                setShowSuggestionPanel(false);
            }
        }
    };

    const addTag = (tagText: string) => {
        const existTag = chipData.some(
            chip => chip.toLocaleLowerCase() === tagText.toLocaleLowerCase()
        );
        if (!existTag) {
            const newChips = [...chipData, tagText];
            setChipData(newChips);
            if (onChipsChange) {
                onChipsChange(newChips);
            }
        }
        setText('');
    };

    return (
        <>
            {!readonly && (
                <Grid item xs={columnsInput ? (columnsInput as GridSize) : 12}>
                    <TextField
                        ref={textField}
                        style={{ marginBottom: '6px' }}
                        variant='filled'
                        size='small'
                        hiddenLabel
                        value={text}
                        fullWidth
                        inputProps={{ maxLength: 25 }}
                        placeholder='Enter tags...'
                        onChange={e => onChangeText(e.target.value)}
                        onKeyPress={ev => {
                            if (ev.key === 'Enter') {
                                addTag(text);
                                ev.preventDefault();
                            }
                        }}
                    />
                    <SuggestionPanel
                        appendTo={textField}
                        show={showSuggestionPanel}
                        options={suggestedChips || []}
                        searchedText={text}
                        onSelect={addTag}
                    />
                </Grid>
            )}
            <Grid item xs={12}>
                <Paper
                    component='ul'
                    className={classes.root}
                    elevation={0}
                    style={{ marginBottom: '0px' }}
                >
                    {chipData.map((data, index) => (
                        <li
                            key={index}
                            className={classChipContainer}
                            onClick={() => onChipClicked && onChipClicked(data)}
                        >
                            {!readonly && (
                                <Chip
                                    color='primary'
                                    size='small'
                                    label={data}
                                    deleteIcon={
                                        <ClearIcon
                                            style={{ color: 'white' }}
                                            fontSize='small'
                                        />
                                    }
                                    onDelete={handleDelete(data)}
                                    className={`chip-element chip-delete ${classes.chip}`}
                                    style={{ height: '24px' }}
                                />
                            )}
                            {readonly && (
                                <Chip
                                    color='primary'
                                    size='small'
                                    label={data}
                                    className={`chip-element ${classes.chip}`}
                                    style={{ height: '22px' }}
                                />
                            )}
                        </li>
                    ))}
                </Paper>
            </Grid>
        </>
    );
}

import React from 'react';
import { LinkButton } from './LinkButton';

type Props = {
    text: string;
    to: string;
};

export default function BackButton({ text, to }: Props): JSX.Element {
    return (
        <LinkButton
            to={to}
            sx={{ marginLeft: 'auto' }}
            variant='text'
            iconId='chevron-left'
            text={text}
            size='XL'
        />
    );
}

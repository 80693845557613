import React, { Fragment, useState } from 'react';
import { Typography } from '@mui/material';
import WorkflowTemplateService from '../../services/WorkflowTemplateService';
import { ExportedWorkflowMetadata } from '../../models/ExportedWorkflowMetadata';
import { useSnackbar } from 'notistack';
import { UploadButton } from '@connectif/ui-components';

type Props = {
    onTemplateSelected: (
        d: ExportedWorkflowMetadata & { token: string }
    ) => void;
};

async function getTokenFromFile(file?: File): Promise<string> {
    if (!file) {
        return '';
    }

    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.addEventListener('load', event => {
            try {
                if (typeof event.target?.result !== 'string') {
                    return reject('Invalid file');
                }
                const { token } = JSON.parse(event.target.result);
                resolve(token);
            } catch (error) {
                reject(error);
            }
        });
        reader.readAsText(file);
    });
}

export default function TemplateUpload({
    onTemplateSelected
}: Props): JSX.Element {
    const { enqueueSnackbar } = useSnackbar();
    const [fileName, setFileName] = useState('');
    const onFileChanged = async (file?: File) => {
        if (!file) {
            return;
        }

        try {
            const token = await getTokenFromFile(file);
            const exportedWorkflowMetadata =
                await WorkflowTemplateService.getExportedWorkflowMetadata(
                    token
                );
            setFileName(file.name);
            onTemplateSelected({ ...exportedWorkflowMetadata, token });
        } catch {
            enqueueSnackbar(
                'There was an error retrieving workflow template metadata. Check file or try again later.',
                { variant: 'error' }
            );
        }
    };

    return (
        <Fragment>
            <UploadButton
                variant='outlined'
                iconId={'upload'}
                text='Upload'
                accept='.cn'
                onFilesChanged={files => onFileChanged(files[0])}
            />
            <Typography
                component='span'
                color='textSecondary'
                style={{ marginLeft: 10 }}
            >
                {fileName}
            </Typography>
        </Fragment>
    );
}

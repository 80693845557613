import Overlay from 'react-bootstrap/Overlay';
import React, { useCallback } from 'react';
import './SuggestionPanel.css';

type Props = {
    appendTo: React.RefObject<HTMLDivElement>;
    show: boolean;
    options: string[];
    searchedText?: string;
    onSelect: (selectedText: string) => void;
    style?: React.CSSProperties;
};

export default function SuggestionPanel({
    appendTo,
    show,
    options,
    searchedText,
    onSelect
}: Props): JSX.Element {
    const [optionsFiltered, setOptionsFiltered] = React.useState<string[]>([]);
    const [searchText, setSearchText] = React.useState<string>();

    const calculateOptionsFiltered = useCallback((): string[] => {
        if (!searchText || searchText.length < 2) {
            return [];
        }
        const optionsFiltered = options.filter(option =>
            option.toLocaleLowerCase().includes(searchText.toLocaleLowerCase())
        );
        return optionsFiltered && optionsFiltered.length ? optionsFiltered : [];
    }, [options, searchText]);

    const reloadSearchText = useCallback(() => {
        setSearchText(searchedText);
    }, [searchedText]);

    React.useEffect(() => {
        reloadSearchText();
        setOptionsFiltered(calculateOptionsFiltered());
    }, [searchedText, calculateOptionsFiltered, reloadSearchText]);

    return (
        <Overlay target={appendTo.current} show={show} placement='top'>
            {({ placement, arrowProps, show: _show, popper, ...props }) => (
                <ul
                    className={'suggestion-panel'}
                    {...props}
                    style={{
                        margin: 0,
                        padding: 0,
                        width: `${appendTo.current?.offsetWidth}px`,
                        ...props.style
                    }}
                >
                    {optionsFiltered.map((option: string, index) => {
                        const text = searchText || '';
                        const textsWithoutHighlight = option
                            .toLocaleLowerCase()
                            .split(text.toLocaleLowerCase());
                        let textOption = option;
                        return (
                            <li
                                key={index}
                                onClick={ev => {
                                    onSelect(option);
                                    ev.preventDefault();
                                }}
                            >
                                {textsWithoutHighlight.map(
                                    (textWithoutHighlight, indexText) => {
                                        textOption = textOption.substr(
                                            textWithoutHighlight.length
                                        );
                                        const nameText = textOption.substr(
                                            0,
                                            text.length
                                        );
                                        textOption = textOption.substr(
                                            text.length
                                        );
                                        return (
                                            <span key={indexText}>
                                                {textWithoutHighlight}
                                                <span className={'highlight'}>
                                                    <strong>{nameText}</strong>
                                                </span>
                                            </span>
                                        );
                                    }
                                )}
                            </li>
                        );
                    })}
                </ul>
            )}
        </Overlay>
    );
}

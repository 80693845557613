import * as React from 'react';
import { Button, ButtonProps } from '@connectif/ui-components';
import { Link } from 'react-router-dom';

type LinkButtonProps = { to: string } & ButtonProps;

const OverridableButton = (
    props: ButtonProps & {
        link: React.ElementType;
        to: string;
    }
): JSX.Element => <Button {...props} />;

export const LinkButton = ({ to, ...buttonProps }: LinkButtonProps) => (
    <OverridableButton link={Link} to={to} {...buttonProps} />
);

import React, { Fragment, useEffect, useRef } from 'react';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Checkbox, Divider, Grid } from '@mui/material';
import { border } from '../../Theme';
import { WorkflowTemplateFilterListData } from '../../models/WorkflowTemplateFilterListData';
import { WorkflowTemplateListFilters } from '../../models/WorkflowTemplateListFilters';
import MiddleEllipsis from '../../components/MiddleEllipsis';
import clsx from 'clsx';
import SearchBox from '../../components/SearchBox';
import { useDebouncedCallback } from '../../components/useDebouncedCallback';
import { TemplateFilterData } from '../../models/WorkflowTemplateFilter';

type Props = {
    filters: WorkflowTemplateListFilters;
    filterListData: WorkflowTemplateFilterListData[];
    onChangeFilters: (filters: WorkflowTemplateListFilters) => void;
    onCleanFilters: () => void;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        titleContainer: {
            display: 'flex',
            height: '38px',
            padding: '10px',
            boxSizing: 'border-box'
        },
        title: {
            fontFamily: 'Source Sans Pro',
            color: '#47535d',
            fontSize: '18px',
            fontWeight: 400
        },
        subtitle: {
            display: 'inline-flex',
            paddingLeft: '10px',
            color: theme.palette.primary.main,
            cursor: 'pointer',
            fontSize: '14px'
        },
        list: {
            padding: '8px',
            minHeight: '567px',
            '& > nav': {
                boxShadow:
                    '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)'
            }
        },
        root: {
            width: '100%',
            backgroundColor: theme.palette.background.paper,
            border,
            borderRadius: '2px',
            paddingBottom: '40px'
        },
        nested: {
            paddingLeft: theme.spacing(3),
            paddingTop: '0px',
            paddingBottom: '0px',
            marginTop: '2px'
        },
        parent: {
            marginTop: '10px',
            padding: '8px 7px 5px 6px'
        },
        checkboxIcon: {
            borderRadius: 2,
            border: '2px solid #83878a',
            width: 16,
            height: 16,
            backgroundColor: 'white',
            'input:hover ~ &': {
                backgroundColor: 'white'
            }
        },
        checkIcon: {
            backgroundColor: theme.palette.primary.main,
            backgroundImage: `linear-gradient(180deg,${theme.palette.primary.main},hsla(0,0%,100%,0))`,
            '&:before': {
                display: 'block',
                width: 20,
                height: 20,
                marginTop: '-2px',
                marginLeft: '-2px'
            },
            'input:hover ~ &': {
                backgroundColor: theme.palette.primary.main
            },
            border: `2px solid ${theme.palette.primary.main}`
        },
        checkedIcon: {
            '&:before': {
                '-webkit-transform': 'rotate(45deg)',
                transform: 'rotate(45deg)',
                position: 'absolute',
                left: '17px',
                top: '4px',
                width: '6.66667px',
                height: '13.33333px',
                borderWidth: '2px',
                'border-style': 'solid',
                borderColor: 'white',
                boxSizing: 'border-box',
                content: '""'
            }
        },
        checkedIconDeleteBorder: {
            '&:before': {
                'border-top': 0,
                'border-left': 0
            }
        },
        checkedIconParent: {
            '&:before': {
                left: '24px'
            }
        },
        indeterminateIcon: {
            '&:before': {
                boxSizing: 'border-box',
                borderColor: 'white',
                position: 'absolute',
                top: '60%',
                left: '25px',
                webkitTransform: 'translate(-25%, -50%)',
                transform: 'translate(-25%, -50%)',
                display: 'table',
                width: '12px',
                height: '2px',
                borderWidth: '1px',
                borderStyle: 'solid',
                borderTop: 0,
                borderLeft: 0,
                content: '""'
            }
        },
        searchBoxContainer: {
            margin: '10px',
            '& > div': {
                width: '100%'
            }
        }
    })
);

export default function TemplateListFilters({
    filterListData,
    filters,
    onChangeFilters,
    onCleanFilters
}: Props): JSX.Element {
    const classes = useStyles();

    const [searchText, setSearchText] = React.useState(filters.searchText);
    const [allFilters, setAllFilters] =
        React.useState<WorkflowTemplateListFilters>(filters);
    const [filtersData, setFiltersData] = React.useState<
        WorkflowTemplateFilterListData[]
    >(
        filterListData.map(filterData => ({
            name: filterData.name,
            checked: filterData.checked,
            open: filterData.open,
            children: filterData.children
        }))
    );

    const liItemTextChildElement = useRef<HTMLLIElement>(null);

    useEffect(() => {
        setAllFilters(filters);
    }, [filters]);

    const getTemplateFiltersSelectedFromType = (
        filtersInfo: WorkflowTemplateFilterListData[],
        type: string
    ): TemplateFilterData[] | undefined => {
        const parentFilter = filtersInfo.find(
            filterInfo => filterInfo.name === type
        );
        if (parentFilter && parentFilter.children) {
            return parentFilter.children
                .filter(child => child.checked)
                .map(child => ({
                    id: child.id,
                    translation: child.translation
                }));
        }
        return undefined;
    };

    const changeFilters = (filtersInfo: WorkflowTemplateFilterListData[]) => {
        setFiltersData(filtersInfo);
        const {
            goalIds: goals,
            packIds: packs,
            levelIds: levels,
            tags: tagsData,
            channelIds: channels,
            ...rest
        } = filters;
        const goalIds = getTemplateFiltersSelectedFromType(filtersInfo, 'goal');
        const channelIds = getTemplateFiltersSelectedFromType(
            filtersInfo,
            'channel'
        );
        const packIds = getTemplateFiltersSelectedFromType(filtersInfo, 'pack');
        const levelIds = getTemplateFiltersSelectedFromType(
            filtersInfo,
            'level'
        );
        const tags = getTemplateFiltersSelectedFromType(filtersInfo, 'tags');
        onChangeFilters({
            ...rest,
            ...(goalIds &&
                goalIds.length && {
                    goalIds: goalIds as TemplateFilterData[]
                }),
            ...(channelIds &&
                channelIds.length && {
                    channelIds: channelIds as TemplateFilterData[]
                }),
            ...(packIds &&
                packIds.length && {
                    packIds: packIds as TemplateFilterData[]
                }),
            ...(levelIds &&
                levelIds.length && {
                    levelIds: levelIds as TemplateFilterData[]
                }),
            ...(tags &&
                tags.length && {
                    tags: tags.map(tag => tag.id)
                })
        });
    };

    const handleParentToggleChecked =
        (filter: WorkflowTemplateFilterListData) => () => {
            const filtersInfo = [...filtersData];
            const parentChecked = filtersInfo.find(f => f.name === filter.name);
            if (parentChecked) {
                parentChecked.checked =
                    parentChecked.checked === undefined
                        ? true
                        : !parentChecked.checked;
                if (parentChecked.children) {
                    parentChecked.children.map(
                        child => (child.checked = !!parentChecked.checked)
                    );
                }
                changeFilters(filtersInfo);
            }
        };

    const handleChildrenToggleChecked =
        (
            filter: WorkflowTemplateFilterListData,
            childChecked: { id: string; checked: boolean }
        ) =>
        () => {
            const filtersInfo = [...filtersData];
            const parentChecked = filtersInfo.find(f => f.name === filter.name);
            if (parentChecked && parentChecked.children) {
                const child = parentChecked.children.find(
                    children => children.id === childChecked.id
                );
                if (child) {
                    child.checked = !child.checked;
                    changeFilters(filtersInfo);
                }
            }
        };

    const onChangeFiltersDebounced = useDebouncedCallback(onChangeFilters, 500);

    function getParentCheck(
        children: { id: string; checked: boolean }[]
    ): string {
        if (children.every(child => child.checked)) {
            return 'true';
        }
        if (children.every(child => !child.checked)) {
            return 'false';
        }
        return 'undefined';
    }

    function handleParentToggleOpen(
        e: React.MouseEvent<SVGSVGElement, MouseEvent>,
        filter: WorkflowTemplateFilterListData
    ) {
        const filtersInfo = [...filtersData];
        const parentChecked = filtersInfo.find(f => f.name === filter.name);
        if (parentChecked) {
            parentChecked.open = !parentChecked.open;
            setFiltersData(filtersInfo);
        }
        e.stopPropagation();
    }
    const isFilterChecked = (
        filter: WorkflowTemplateFilterListData,
        childChecked?: { id: string; checked: boolean }
    ): string => {
        const parentFilter = filtersData.find(
            filterData => filterData.name === filter.name
        );

        if (parentFilter && parentFilter.children && childChecked) {
            const childFilter = parentFilter.children.find(
                children => children.id === childChecked.id
            );
            const checked = !!childFilter && childFilter.checked;
            return checked.toString();
        }
        return parentFilter && parentFilter.children
            ? getParentCheck(parentFilter.children)
            : 'false';
    };

    const isChecked = (
        filter: WorkflowTemplateFilterListData,
        childChecked?: { id: string; checked: boolean }
    ): boolean => {
        const checked = isFilterChecked(filter, childChecked);
        return checked === 'true' || checked === 'undefined';
    };
    const isFilterOpen = (
        filter: WorkflowTemplateFilterListData
    ): boolean | undefined => {
        const parentFilter = filtersData.find(
            filterData => filterData.name === filter.name
        );
        return parentFilter && parentFilter.open;
    };

    const deleteFilters = () => {
        const filtersInfo = [...filtersData];
        filtersInfo.map(filterInfo => {
            filterInfo.checked = false;
            if (filterInfo.children) {
                filterInfo.children.map(child => (child.checked = false));
            }
            return filterInfo;
        });

        changeFilters(filtersInfo);
        setSearchText('');
        onCleanFilters();
    };

    const getElementWidth = (
        element: React.RefObject<HTMLLIElement>
    ): number => {
        const currentElement = element.current;
        return currentElement ? currentElement.offsetWidth : 0;
    };

    const isFiltering =
        allFilters.searchText !== '' ||
        allFilters.cultureCodes ||
        allFilters.isPublished !== undefined ||
        allFilters.goalIds ||
        allFilters.channelIds ||
        allFilters.levelIds ||
        allFilters.packIds ||
        allFilters.tags;

    return (
        <Grid container spacing={2} className={classes.list}>
            <List
                component='nav'
                aria-labelledby='nested-list-subheader'
                subheader={
                    <div className={classes.titleContainer}>
                        <div className={classes.title}>
                            Filters{' '}
                            {isFiltering && (
                                <span
                                    className={classes.subtitle}
                                    onClick={deleteFilters}
                                >
                                    Clean filters
                                </span>
                            )}
                        </div>
                    </div>
                }
                className={classes.root}
            >
                <Divider style={{ height: '2px' }} />
                <div className={classes.searchBoxContainer}>
                    <SearchBox
                        value={searchText}
                        placeholder='Search by template name or description'
                        onChange={searchText => {
                            onChangeFiltersDebounced({
                                ...filters,
                                searchText
                            });
                            setSearchText(searchText);
                        }}
                    />
                </div>
                {filterListData &&
                    filterListData.length &&
                    filterListData.map((filter, index) => {
                        const labelId = `checkbox-list-label-${filter.name}-${index}`;

                        return (
                            <Fragment key={`fragment-${filter.name}-${index}`}>
                                <ListItem
                                    role={undefined}
                                    dense
                                    button
                                    onClick={handleParentToggleChecked(filter)}
                                    className={classes.parent}
                                >
                                    <ListItemIcon style={{ minWidth: 0 }}>
                                        <Checkbox
                                            style={{
                                                padding: '0px 9px 0px 16px'
                                            }}
                                            edge='start'
                                            checked={isChecked(filter)}
                                            tabIndex={-1}
                                            indeterminate={
                                                isFilterChecked(filter) ===
                                                'undefined'
                                            }
                                            disableRipple
                                            color='primary'
                                            inputProps={{
                                                'aria-labelledby': labelId
                                            }}
                                            icon={
                                                <span
                                                    className={
                                                        classes.checkboxIcon
                                                    }
                                                />
                                            }
                                            indeterminateIcon={
                                                <span
                                                    className={clsx(
                                                        classes.checkboxIcon,
                                                        classes.checkIcon,
                                                        classes.indeterminateIcon
                                                    )}
                                                />
                                            }
                                            checkedIcon={
                                                <span
                                                    className={clsx(
                                                        classes.checkboxIcon,
                                                        classes.checkIcon,
                                                        classes.checkedIcon,
                                                        classes.checkedIconDeleteBorder,
                                                        classes.checkedIconParent
                                                    )}
                                                />
                                            }
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        style={{
                                            textTransform: 'capitalize',
                                            margin: 0,
                                            maxWidth: '100%',
                                            color: '#47535d'
                                        }}
                                        id={labelId}
                                        primary={
                                            <MiddleEllipsis
                                                text={filter.name}
                                                parentWidth={200}
                                                font={16}
                                            />
                                        }
                                    />
                                    {isFilterOpen(filter) ? (
                                        <ExpandLess
                                            onClick={e => {
                                                handleParentToggleOpen(
                                                    e,
                                                    filter
                                                );
                                            }}
                                        />
                                    ) : (
                                        <ExpandMore
                                            onClick={e => {
                                                handleParentToggleOpen(
                                                    e,
                                                    filter
                                                );
                                            }}
                                        />
                                    )}
                                </ListItem>
                                <Collapse
                                    in={isFilterOpen(filter)}
                                    timeout='auto'
                                    unmountOnExit
                                >
                                    {filter.children &&
                                        filter.children.length &&
                                        filter.children.map(
                                            (child, childrenIndex) => {
                                                const key = `${filter.name}-${index}-${childrenIndex}`;
                                                return (
                                                    <List
                                                        component='div'
                                                        disablePadding
                                                        key={key}
                                                        onClick={handleChildrenToggleChecked(
                                                            filter,
                                                            child
                                                        )}
                                                    >
                                                        <ListItem
                                                            button
                                                            className={
                                                                classes.nested
                                                            }
                                                        >
                                                            <ListItemIcon
                                                                style={{
                                                                    minWidth: 0
                                                                }}
                                                            >
                                                                <Checkbox
                                                                    style={{
                                                                        padding:
                                                                            '0px 9px 0px 9px'
                                                                    }}
                                                                    edge='start'
                                                                    checked={isChecked(
                                                                        filter,
                                                                        child
                                                                    )}
                                                                    tabIndex={
                                                                        -1
                                                                    }
                                                                    disableRipple
                                                                    color='primary'
                                                                    inputProps={{
                                                                        'aria-labelledby':
                                                                            labelId
                                                                    }}
                                                                    icon={
                                                                        <span
                                                                            className={
                                                                                classes.checkboxIcon
                                                                            }
                                                                        />
                                                                    }
                                                                    checkedIcon={
                                                                        <span
                                                                            className={clsx(
                                                                                classes.checkboxIcon,
                                                                                classes.checkIcon,
                                                                                classes.checkedIcon,
                                                                                classes.checkedIconDeleteBorder
                                                                            )}
                                                                        />
                                                                    }
                                                                />
                                                            </ListItemIcon>
                                                            <ListItemText
                                                                ref={
                                                                    liItemTextChildElement
                                                                }
                                                                style={{
                                                                    textTransform:
                                                                        filter.name !==
                                                                        'tags'
                                                                            ? 'capitalize'
                                                                            : undefined,
                                                                    margin: 0,
                                                                    maxWidth:
                                                                        '100%',
                                                                    color: '#47535d'
                                                                }}
                                                                primary={
                                                                    <MiddleEllipsis
                                                                        text={
                                                                            child.translation
                                                                        }
                                                                        parentWidth={getElementWidth(
                                                                            liItemTextChildElement
                                                                        )}
                                                                    />
                                                                }
                                                            />
                                                        </ListItem>
                                                    </List>
                                                );
                                            }
                                        )}
                                </Collapse>
                            </Fragment>
                        );
                    })}
            </List>
        </Grid>
    );
}

import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions
} from '@mui/material';
import { WorkflowTemplate } from '../../models/WorkflowTemplate';
import { Button } from '@connectif/ui-components';

type WorkflowTemplatePublishConfirmation = Pick<WorkflowTemplate, 'name'>;

type Props = {
    open: boolean;
    template: WorkflowTemplatePublishConfirmation;
    isPublish?: boolean;
    onClose: () => void;
    onSetPublish: (
        template: WorkflowTemplatePublishConfirmation,
        value: boolean
    ) => void;
};

PublishTemplateConfirmationDialog.defaultProps = {
    value: true
};

export default function PublishTemplateConfirmationDialog({
    open,
    template,
    isPublish,
    onClose,
    onSetPublish
}: Props): JSX.Element {
    return (
        <Dialog open={open} onClose={onClose} maxWidth='md'>
            <DialogTitle>Warning</DialogTitle>
            <DialogContent>
                <p>
                    Are you sure you want to{' '}
                    {isPublish ? 'publish' : 'unpublish'} the template{' '}
                    <b>{template.name}</b>?
                </p>
            </DialogContent>
            <DialogActions>
                <Button
                    iconId='check'
                    variant='contained'
                    onClick={() => onSetPublish(template, isPublish ?? true)}
                    text={isPublish ? 'Publish Template' : 'Unpublish Template'}
                />
                <Button
                    iconId='close'
                    variant='text'
                    onClick={onClose}
                    text='Cancel'
                />
            </DialogActions>
        </Dialog>
    );
}

import React, { useState, useEffect } from 'react';
import { Button, Header, PageWithHeader } from '@connectif/ui-components';
import BackButton from '../../components/BackButton';
import { useParams } from 'react-router-dom';
import { WorkflowTemplateEdit } from '../../models/WorkflowTemplateEdit';
import WorkflowTemplateService from '../../services/WorkflowTemplateService';
import TemplateEditor from './TemplateEditor';
import PublishTemplateConfirmationDialog from './PublishTemplateConfirmationDialog';
import { useSnackbar } from 'notistack';

export default function TemplateEdit() {
    const { enqueueSnackbar } = useSnackbar();
    const { id } = useParams<{ id: string }>();
    const [template, setTemplate] = useState<WorkflowTemplateEdit>();
    const [publishConfirmationOpen, setPublishConfirmationOpen] =
        useState(false);

    useEffect(() => {
        id && void WorkflowTemplateService.getTemplate(id).then(setTemplate);
    }, [id]);

    if (!id) {
        return null;
    }

    const save = async (template: WorkflowTemplateEdit) => {
        try {
            const updatedTemplate =
                await WorkflowTemplateService.updateTemplate(id, template);
            (updatedTemplate as WorkflowTemplateEdit).previewTags =
                template.previewTags;
            setTemplate(updatedTemplate);
            enqueueSnackbar('Workflow template updated successfully', {
                variant: 'success'
            });
        } catch (err) {
            enqueueSnackbar(
                `There was an error on update workflow template. ${err?.message}`,
                {
                    variant: 'error'
                }
            );
        }
    };

    const isValid =
        template &&
        template.name &&
        template.description &&
        template.thumbnailImageUrl &&
        template.token &&
        (!template.priority ||
            (template.priority >= 0 && template.priority <= 999));

    return (
        <PageWithHeader
            header={
                <Header
                    title='Edit template'
                    subtitle='Edit the template attributes'
                    actions={
                        <>
                            <BackButton
                                to='/template/list'
                                text='Back to template list'
                            />
                            {template && (
                                <>
                                    <Button
                                        variant='contained'
                                        iconId='check'
                                        onClick={() => save(template)}
                                        disabled={!isValid}
                                        text='Update'
                                        size='XL'
                                    />
                                    <Button
                                        variant='contained'
                                        iconId='check'
                                        onClick={() =>
                                            setPublishConfirmationOpen(true)
                                        }
                                        disabled={!isValid}
                                        text='Update and Publish'
                                        size='XL'
                                    />
                                </>
                            )}
                        </>
                    }
                />
            }
        >
            {template && (
                <>
                    <TemplateEditor
                        template={template}
                        onChangeTemplate={setTemplate}
                    />
                    <PublishTemplateConfirmationDialog
                        open={publishConfirmationOpen}
                        isPublish={true}
                        template={template}
                        onClose={() => setPublishConfirmationOpen(false)}
                        onSetPublish={() => {
                            void save({ ...template, isPublished: true });
                            setPublishConfirmationOpen(false);
                        }}
                    />
                </>
            )}
        </PageWithHeader>
    );
}

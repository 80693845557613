import React, { PropsWithChildren, CSSProperties } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import { Fade } from '@mui/material';
import './Alert.css';

interface Props {
    visible?: boolean;
    variant: 'success' | 'warning' | 'error' | 'info';
    style?: CSSProperties;
}

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon
};

Alert.defaultProps = {
    visible: true
};

export default function Alert(props: PropsWithChildren<Props>): JSX.Element {
    const Icon = variantIcon[props.variant];

    return (
        <Fade in={props.visible}>
            <div
                className={`alert alert-${props.variant} ${
                    props.visible ? 'visible' : ''
                }`}
                style={props.style}
            >
                <div className='icon-container'>
                    <Icon />
                </div>
                {props.children}
            </div>
        </Fade>
    );
}

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import marketplaceTheme from './Theme';
import { GlobalStyles, Theme } from '@connectif/ui-components';
import { createTheme } from '@mui/material';
import { GlobalStyles as MuiGlobalStyles } from '@mui/material';

const theme = createTheme(marketplaceTheme, Theme.theme);

ReactDOM.render(
    <React.StrictMode>
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <MuiGlobalStyles styles={GlobalStyles.default} />
                <App />
            </ThemeProvider>
        </StyledEngineProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

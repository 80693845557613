import { API_BASE_URL } from '../Config';
import { BadRequestError } from '../models/errors/BadRequestError';
import { NotFoundError } from '../models/errors/NotFoundError';
import { UnauthorizedError } from '../models/errors/UnauthorizedError';
import { DuplicateKeyError } from '../models/errors/DuplicateKeyError';
import AuthService from './AuthService';

type IRequestService = {
    cnFetch<T>(
        path: string,
        init?: RequestInit,
        queryParams?: Record<string, string | boolean | string[]>
    ): Promise<T>;
};

type RequestOptions = {
    headers: Headers;
    method: string;
    body: string;
};

function objToQueryString(obj: Record<string, string | boolean | string[]>) {
    return Object.entries(obj)
        .filter(([, value]) => value !== null && value !== undefined)
        .flatMap(([key, value]) =>
            Array.isArray(value)
                ? value.map(
                      v => `${encodeURIComponent(key)}=${encodeURIComponent(v)}`
                  )
                : `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        )
        .join('&');
}

const RequestService: IRequestService = {
    cnFetch<T>(
        path: string,
        requestOptions?: RequestOptions,
        queryParams?: Record<string, string | boolean | string[]>
    ): Promise<T> {
        const accessToken = AuthService.getAccessToken();

        const { headers, method, body } = requestOptions ?? {
            headers: new Headers(),
            method: 'GET'
        };
        const requestInit: RequestInit = {
            mode: 'cors',
            headers: new Headers({
                'Content-Type': 'application/json',
                ...(accessToken
                    ? { Authorization: `Bearer ${accessToken}` }
                    : {}),
                ...headers
            }),
            method,
            body
        };

        let url = API_BASE_URL + path;
        if (queryParams) {
            const queryParamsString = objToQueryString(queryParams);
            if (queryParamsString) {
                url = url + '?' + queryParamsString;
            }
        }

        return fetch(url, requestInit).then(async response => {
            // Unauthorized
            if (response.status === 401) {
                AuthService.clearAccessToken();
            }
            if (response.ok) {
                return requestOptions?.method?.toLowerCase() !== 'delete'
                    ? response.json()
                    : undefined;
            } else {
                if (response.status === 400) {
                    const error = await response.json();
                    throw new BadRequestError(error.message);
                }
                if (response.status === 403) {
                    throw new UnauthorizedError();
                }
                if (response.status === 404) {
                    const error = await response.json();
                    throw new NotFoundError(error.message);
                }
                if (response.status === 409) {
                    throw new DuplicateKeyError();
                }
                throw Error(response.statusText);
            }
        });
    }
};
export default RequestService;

import React, { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';
import { Component, CSSProperties } from 'react';
import { Button, Container, Typography } from '@connectif/ui-components';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import AuthService from '../../services/AuthService';
import ValidatorService from '../../services/ValidatorService';
import logo from '../../resources/img/logo-with-text-right.svg';
import './Login.css';
import bkLoginImage from '../../resources/img/login-bg.jpeg';

interface State {
    isUserLoggedIn: boolean;
    password: string;
    email: string;
    invalidCredentials: boolean;
}

const bgContainerStyles: CSSProperties = {
    backgroundImage: `url(${bkLoginImage})`
};

class Login extends Component<unknown, State> {
    constructor(props: unknown) {
        super(props);
        const isUserLoggedIn = AuthService.isUserLoggedIn();
        this.state = {
            isUserLoggedIn,
            password: '',
            email: '',
            invalidCredentials: false
        };
    }

    handleSubmit: React.FormEventHandler<HTMLFormElement> = async event => {
        event.preventDefault();
        if (!this.state.email || !this.state.password) {
            return;
        }

        try {
            const response = await AuthService.login(
                this.state.email,
                this.state.password
            );
            if (!response.success) {
                this.setState({ invalidCredentials: true });
                return;
            }

            if (response.success) {
                this.setState({ isUserLoggedIn: true });
            }
        } catch (error) {
            this.setState({ invalidCredentials: true });
        }
    };

    render(): ReactElement {
        const isValidEmail = ValidatorService.validateEmail(this.state.email);

        if (this.state.isUserLoggedIn === true) {
            return <Navigate replace to='/template/list' />;
        }

        return (
            <div className={'login-bg-container'} style={bgContainerStyles}>
                <div className={'login-container'}>
                    <Container maxWidth='xs'>
                        <CssBaseline />
                        <Card>
                            <CardContent>
                                <div className='logo-container'>
                                    <img
                                        src={logo}
                                        className='App-logo'
                                        alt='logo'
                                    />
                                </div>
                                <div className='logo-title'>
                                    <Typography component='h1' variant='h5'>
                                        MARKETPLACE
                                    </Typography>
                                </div>

                                {this.state.invalidCredentials && (
                                    <Typography
                                        component='h5'
                                        className={'error'}
                                    >
                                        Invalid credentials
                                    </Typography>
                                )}

                                <form noValidate onSubmit={this.handleSubmit}>
                                    <TextField
                                        variant='outlined'
                                        margin='normal'
                                        required
                                        fullWidth
                                        id='email'
                                        label='Email Address'
                                        name='email'
                                        autoComplete='email'
                                        helperText={
                                            isValidEmail ? '' : 'Invalid email'
                                        }
                                        error={!isValidEmail}
                                        onChange={event =>
                                            this.setState({
                                                email: event.target.value
                                            })
                                        }
                                        autoFocus
                                    />
                                    <TextField
                                        variant='outlined'
                                        margin='normal'
                                        required
                                        fullWidth
                                        name='password'
                                        label='Password'
                                        type='password'
                                        id='password'
                                        onChange={event =>
                                            this.setState({
                                                password: event.target.value
                                            })
                                        }
                                        autoComplete='current-password'
                                    />
                                    <Button
                                        iconId='chevron-right'
                                        type='submit'
                                        variant='contained'
                                        text='Sign In'
                                    />
                                </form>
                            </CardContent>
                        </Card>
                    </Container>
                </div>
            </div>
        );
    }
}
export default Login;

import React, { useCallback, useEffect, useState } from 'react';
import {
    Card,
    CardActions,
    CardContent,
    CardMedia,
    Divider,
    Grid,
    Menu,
    MenuItem,
    Theme,
    Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { WorkflowTemplate } from '../../models/WorkflowTemplate';
import DateFormat from '../../components/DateFormat';
import { publishedColor, draftColor } from '../../Theme';
import placeholder from './card-placeholder.svg';
import ExpandableElement from '../../components/ExpandableElement';
import TagListExpandable from '../../components/TagListExpandable';
import './TemplateCard.css';
import { LinkButton } from '../../components/LinkButton';
import { Button } from '@connectif/ui-components';

type WorkflowTemplateCardType = Omit<WorkflowTemplate, 'lastModifiedDate'>;

type Props = {
    template: WorkflowTemplateCardType;
    disabled?: boolean;
    searchText?: string;
    onDelete?: (template: WorkflowTemplateCardType) => void;
    onSetPublish?: (templateId: string, value: boolean) => void;
};

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: 270
    },
    media: {
        height: 130,
        backgroundSize: 'contain'
    },
    publishStatus: {
        textAlign: 'right',
        paddingRight: theme.spacing(1),
        color: 'white',
        fontSize: 12,
        lineHeight: '24px'
    },
    description: {
        marginBottom: 10,
        fontWeight: 400
    },
    propertyValue: {
        marginLeft: 'auto',
        fontWeight: 600
    }
}));

export default function TemplateCard({
    template,
    disabled,
    searchText,
    onDelete,
    onSetPublish
}: Props): JSX.Element {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    const [isTitleExpandable, setIsTitleExpandable] = useState<boolean>(false);
    const [areTagsExpandable, setAreTagsExpandable] = useState<boolean>(false);
    const [forceShowExpandable, setForceShowExpandable] =
        useState<boolean>(false);
    const [templateNameHighlighted, setTemplateNameHighlighted] =
        useState<string>(template.name);
    const [templateDescriptionHighlighted, setTemplateDescriptionHighlighted] =
        useState<string>(template.description);

    const isTitleExpandableCallback = useCallback(() => {
        if (isTitleExpandable) {
            setForceShowExpandable(true);
        }
    }, [isTitleExpandable]);
    const areTagsExpandableCallback = useCallback(() => {
        if (areTagsExpandable) {
            setForceShowExpandable(true);
        }
    }, [areTagsExpandable]);

    useEffect(() => {
        isTitleExpandableCallback();
        areTagsExpandableCallback();
    }, [isTitleExpandableCallback, areTagsExpandableCallback]);

    const highlightText = useCallback(
        (input: string) => {
            if (typeof input !== 'string') {
                return input;
            }

            if (searchText) {
                input = input.replace(
                    new RegExp('(' + escapeRegExp(searchText) + ')', 'gi'),
                    '<span class="highlighted">$1</span>'
                );
            }

            return input;
        },
        [searchText]
    );

    useEffect(() => {
        setTemplateNameHighlighted(highlightText(template.name));
        setTemplateDescriptionHighlighted(highlightText(template.description));
    }, [template, highlightText]);

    const classes = useStyles();

    return (
        <Card className={classes.root} elevation={2}>
            <CardMedia
                className={classes.media}
                image={template.thumbnailImageUrl || placeholder}
            />
            <div
                className={classes.publishStatus}
                style={{
                    backgroundColor: template.isPublished
                        ? publishedColor
                        : draftColor
                }}
            >
                {template.isPublished ? 'Published' : 'Draft'}
            </div>
            <CardContent style={{ padding: 0 }}>
                <div
                    className={'card-body-container'}
                    style={{
                        maxHeight: !isExpanded ? '196px' : '1000px'
                    }}
                >
                    <ExpandableElement
                        maxHeightCollapsed={50}
                        noExpandable
                        forceExpanded={isExpanded}
                        onSetIsExpandable={isExpandable =>
                            setIsTitleExpandable(isExpandable)
                        }
                    >
                        <Typography
                            variant='h6'
                            dangerouslySetInnerHTML={{
                                __html: templateNameHighlighted
                            }}
                        ></Typography>
                    </ExpandableElement>
                    <TagListExpandable
                        tags={template.tags ? [...template.tags] : []}
                        maxHeightCollapsed={73}
                        forceExpanded={isExpanded}
                        onSetIsExpandable={isExpandable =>
                            setAreTagsExpandable(isExpandable)
                        }
                        onChangeExpanded={expanded => setIsExpanded(expanded)}
                    ></TagListExpandable>
                    <ExpandableElement
                        maxHeightCollapsed={74}
                        forceExpanded={isExpanded}
                        forceShowExpanded={forceShowExpandable || isExpanded}
                        onChangeExpanded={expanded => setIsExpanded(expanded)}
                    >
                        <Typography
                            variant='body2'
                            color='textSecondary'
                            component='p'
                            className={classes.description}
                            dangerouslySetInnerHTML={{
                                __html: templateDescriptionHighlighted
                            }}
                        ></Typography>
                    </ExpandableElement>
                </div>
                <Divider style={{ marginBottom: 7 }} />
                <div style={{ padding: '16px' }}>
                    <Grid container>
                        <Typography color='textSecondary' component='span'>
                            Created at
                        </Typography>
                        <Typography
                            component='span'
                            className={classes.propertyValue}
                        >
                            <DateFormat date={template.creationDate} />
                        </Typography>
                    </Grid>
                    <Grid container>
                        <Typography color='textSecondary' component='span'>
                            Published at
                        </Typography>
                        <Typography
                            component='span'
                            className={classes.propertyValue}
                        >
                            <DateFormat date={template.lastPublishedDate} />
                        </Typography>
                    </Grid>
                    <Grid container>
                        <Typography color='textSecondary' component='span'>
                            Language
                        </Typography>
                        <Typography
                            component='span'
                            className={classes.propertyValue}
                        >
                            {template.cultureCode === 'es'
                                ? 'Spanish'
                                : 'English'}
                        </Typography>
                    </Grid>
                    <Grid container>
                        <Typography color='textSecondary' component='span'>
                            Level
                        </Typography>
                        <Typography
                            component='span'
                            className={classes.propertyValue}
                            style={{ textTransform: 'capitalize' }}
                        >
                            {template.levelId}
                        </Typography>
                    </Grid>
                    <Grid container>
                        <Typography color='textSecondary' component='span'>
                            Priority
                        </Typography>
                        <Typography
                            component='span'
                            className={classes.propertyValue}
                            style={{ textTransform: 'capitalize' }}
                        >
                            {template.priority === undefined
                                ? '-'
                                : template.priority}
                        </Typography>
                    </Grid>
                </div>
            </CardContent>
            <Divider />
            <CardActions disableSpacing>
                <LinkButton
                    variant='text'
                    to={`/template/${template.id}/edit`}
                    iconId='pencil'
                    disabled={disabled}
                    text='Edit'
                />
                <Button
                    variant='text'
                    iconId='chevron-down'
                    iconEnd
                    sx={{ marginLeft: 'auto' }}
                    onClick={event => setAnchorEl(event.currentTarget)}
                    disabled={disabled}
                    text='More'
                />
                <Menu
                    id='simple-menu'
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}
                >
                    {!template.isPublished && (
                        <MenuItem
                            onClick={() => {
                                onSetPublish && onSetPublish(template.id, true);
                                setAnchorEl(null);
                            }}
                        >
                            Publish
                        </MenuItem>
                    )}
                    {template.isPublished && (
                        <MenuItem
                            onClick={() => {
                                onSetPublish &&
                                    onSetPublish(template.id, false);
                                setAnchorEl(null);
                            }}
                        >
                            Unpublish
                        </MenuItem>
                    )}
                    <MenuItem
                        onClick={() => {
                            onDelete?.(template);
                            setAnchorEl(null);
                        }}
                    >
                        Delete
                    </MenuItem>
                </Menu>
            </CardActions>
        </Card>
    );
}

function escapeRegExp(input: string) {
    return input.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

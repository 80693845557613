import React, { useCallback, useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Spinner from '../../components/Spinner';
import { WorkflowTemplate } from '../../models/WorkflowTemplate';
import { WorkflowTemplateListFilters } from '../../models/WorkflowTemplateListFilters';
import TemplateListItems from './TemplateListItems';
import WorkflowTemplateService from '../../services/WorkflowTemplateService';
import TemplateListFilters from './TemplateListFilters';
import Alert from '../../components/Alert';
import { useSnackbar } from 'notistack';
import TemplateFilters from './TemplateFilters';
import { useWorkflowTemplateFilterData } from '../../hooks/UseWorkflowTemplateFilterData';
import { LinkButton } from '../../components/LinkButton';
import { PageWithHeader, Grid, Header } from '@connectif/ui-components';

const useStyles = makeStyles(() => ({
    counterText: {
        fontFamily: 'Source Sans Pro',
        fontSize: '14px',
        color: '#47535d',
        fontWeight: 400,
        '& > span': {
            fontSize: '16px',
            fontWeight: 600
        }
    },
    'workflow-template-list-container': {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: '16px',
        width: '100%',
        '& .workflow-filters-container': {
            width: '267px',
            flexShrink: 0,
            flexGrow: 1
        },
        '& .workflow-templates-container': {
            width: 'calc(100% - 283px)',
            marginLeft: '16px',
            display: 'flex',
            flexWrap: 'wrap',
            minWidth: '286px',
            alignContent: 'flex-start'
        }
    }
}));

export default function TemplateList(): JSX.Element {
    const classes = useStyles();

    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [isLoadingTemplates, setLoadingTemplates] = useState(false);
    const [templates, setTemplates] = useState<WorkflowTemplate[]>([]);
    const [filters, setFilters] = useState<WorkflowTemplateListFilters>({
        searchText: ''
    });
    const {
        filtersListData,
        loading: loadingFiltersData,
        filtersData,
        setFiltersListData
    } = useWorkflowTemplateFilterData();

    const cleanFilters = () => {
        setFilters({
            searchText: ''
        });

        const filtersInfo = [...filtersListData];
        filtersInfo.map(filterInfo => {
            filterInfo.checked = false;
            if (filterInfo.children) {
                filterInfo.children.map(child => (child.checked = false));
            }
            return filterInfo;
        });

        if (setFiltersListData) {
            const newData = {
                filtersListData: filtersInfo,
                filtersData,
                loading: loadingFiltersData
            };
            setFiltersListData(newData);
        }
    };

    const reload = useCallback(async () => {
        setLoadingTemplates(true);
        setTemplates(await WorkflowTemplateService.getTemplateList(filters));
        setLoadingTemplates(false);
    }, [filters]);

    useEffect(() => {
        setLoading(true);
        void reload().finally(() => setLoading(false));
    }, [reload]);

    const deleteTemplate = async (templateId: string) => {
        setLoading(true);
        try {
            await WorkflowTemplateService.delete(templateId);
            enqueueSnackbar('Workflow template deleted successfully', {
                variant: 'success'
            });
            await reload();
        } finally {
            setLoading(false);
        }
    };

    const setPublished = async (templateId: string, isPublish: boolean) => {
        setLoading(true);
        try {
            await WorkflowTemplateService.setPublished(templateId, isPublish);
            enqueueSnackbar(
                `Workflow template ${
                    isPublish ? 'published' : 'unpublished'
                } successfully`,
                { variant: 'success' }
            );
            await reload();
        } finally {
            setLoading(false);
        }
    };

    const isFiltering =
        filters.searchText !== '' ||
        filters.cultureCodes ||
        filters.isPublished !== undefined ||
        filters.goalIds ||
        filters.channelIds ||
        filters.levelIds ||
        filters.packIds ||
        filters.tags;

    return (
        <PageWithHeader
            header={
                <Header
                    title='Workflow templates'
                    subtitle='Create new workflow templates or edit existing templates.'
                    actions={
                        <LinkButton
                            to='/template/create'
                            variant='contained'
                            iconId='plus-circle'
                            text='New template'
                            size='XL'
                        />
                    }
                >
                    <TemplateListFilters
                        filters={filters}
                        onChangeFilters={setFilters}
                    />
                </Header>
            }
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    {!isFiltering && (
                        <div
                            className={classes.counterText}
                            style={{
                                visibility: isLoadingTemplates
                                    ? 'hidden'
                                    : undefined
                            }}
                        >
                            Showing <span>{`${templates.length}`}</span>
                            {templates.length === 1
                                ? ' template.'
                                : ' templates.'}
                        </div>
                    )}
                    {isFiltering && (
                        <div
                            className={classes.counterText}
                            style={{
                                visibility: isLoadingTemplates
                                    ? 'hidden'
                                    : undefined
                            }}
                        >
                            Showing a filtered set of{' '}
                            <span>{`${templates.length}`}</span>
                            {templates.length === 1
                                ? ' template.'
                                : ' templates.'}
                        </div>
                    )}
                </Grid>
                <Grid
                    item
                    className={classes['workflow-template-list-container']}
                >
                    <Grid item className={'workflow-filters-container'}>
                        {!loadingFiltersData && (
                            <TemplateFilters
                                filterListData={filtersListData}
                                filters={filters}
                                onChangeFilters={setFilters}
                                onCleanFilters={cleanFilters}
                            />
                        )}
                    </Grid>
                    <Grid item className={'workflow-templates-container'}>
                        <TemplateListItems
                            templates={templates}
                            onDelete={deleteTemplate}
                            setPublished={setPublished}
                            searchText={filters.searchText}
                        />
                        {!isLoading && templates.length === 0 && (
                            <Alert
                                variant='info'
                                style={{
                                    width: '100%',
                                    marginTop: 0,
                                    fontFamily: 'Source Sans Pro'
                                }}
                            >
                                No templates found.
                            </Alert>
                        )}
                    </Grid>
                </Grid>
            </Grid>
            <Spinner visible={isLoading} />
        </PageWithHeader>
    );
}

import React, { useState } from 'react';
import { Button, Header, PageWithHeader } from '@connectif/ui-components';
import BackButton from '../../components/BackButton';
import { WorkflowTemplateEdit } from '../../models/WorkflowTemplateEdit';
import WorkflowTemplateService from '../../services/WorkflowTemplateService';
import { useNavigate } from 'react-router-dom';
import TemplateEditor from './TemplateEditor';
import PublishTemplateConfirmationDialog from './PublishTemplateConfirmationDialog';
import { useSnackbar } from 'notistack';

export default function TemplateCreate(): JSX.Element {
    const { enqueueSnackbar } = useSnackbar();
    const [template, setTemplate] = useState<WorkflowTemplateEdit>({
        name: '',
        description: '',
        thumbnailImageUrl: '',
        knowledgeCenterUrl: '',
        token: '',
        isPublished: false,
        cultureCode: 'es',
        goalIds: ['acquisition'],
        levelId: 'beginner',
        tags: [],
        channelIds: [],
        packIds: [],
        previewTags: ['Goal: Acquisition']
    });
    const navigate = useNavigate();
    const [publishConfirmationOpen, setPublishConfirmationOpen] =
        useState(false);

    const save = async (template: WorkflowTemplateEdit) => {
        try {
            await WorkflowTemplateService.createTemplate(template);
            enqueueSnackbar('Workflow template created successfully', {
                variant: 'success'
            });
            navigate('/template/list');
        } catch (err) {
            enqueueSnackbar(
                'There was an error on create workflow template. ' +
                    err?.message,
                {
                    variant: 'error'
                }
            );
        }
    };

    const isValid =
        template.name &&
        template.description &&
        template.thumbnailImageUrl &&
        template.token &&
        template.levelId &&
        template.goalIds.length;

    return (
        <PageWithHeader
            header={
                <Header
                    title='New template'
                    subtitle='Create a new workflow template and publish it in Connectif'
                    actions={
                        <>
                            <BackButton
                                to='/template/list'
                                text='Back to template list'
                            />
                            <Button
                                variant='contained'
                                iconId='check'
                                onClick={() => save(template)}
                                disabled={!isValid}
                                text='Save'
                                size='XL'
                            />
                            <Button
                                variant='contained'
                                iconId='check'
                                onClick={() => setPublishConfirmationOpen(true)}
                                disabled={!isValid}
                                text='Save and Publish'
                                size='XL'
                            />
                        </>
                    }
                />
            }
        >
            <TemplateEditor
                template={template}
                onChangeTemplate={setTemplate}
            />
            <PublishTemplateConfirmationDialog
                open={publishConfirmationOpen}
                isPublish={true}
                template={template}
                onClose={() => setPublishConfirmationOpen(false)}
                onSetPublish={() => save({ ...template, isPublished: true })}
            />
        </PageWithHeader>
    );
}

import React, { Fragment } from 'react';

type Props = {
    date?: Date;
};

const dateTimeFormatOptions: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
};

export default function DateFormat({ date }: Props): JSX.Element {
    return (
        <Fragment>
            {date
                ? new Intl.DateTimeFormat(
                      'es-ES',
                      dateTimeFormatOptions
                  ).format(date)
                : '-'}
        </Fragment>
    );
}

import React, { useCallback, useEffect } from 'react';
import { WorkflowTemplateEdit } from '../../models/WorkflowTemplateEdit';
import { TextField, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ToggleButtonGroup, ToggleButton } from '@mui/material';
import TemplateCard from './TemplateCard';
import TemplateUpload from './TemplateUpload';
import { useWorkflowTemplateFilterData } from '../../hooks/UseWorkflowTemplateFilterData';
import ChipsSelector from '../../components/ChipsSelector';
import { NumberField, Grid } from '@connectif/ui-components';

type Props = {
    template: WorkflowTemplateEdit;
    onChangeTemplate: (template: WorkflowTemplateEdit) => void;
};

const useStyles = makeStyles(() => ({
    label: {
        lineHeight: '32px',
        marginRight: 10,
        minWidth: 70,
        display: 'inline-block'
    }
}));

export default function TemplateEditor({
    template,
    onChangeTemplate
}: Props): JSX.Element {
    const { filtersData } = useWorkflowTemplateFilterData();
    const [isChangingTags, setIsChangingTags] = React.useState<boolean>(false);
    const [isPriorityError, setIsPriorityError] =
        React.useState<boolean>(false);

    const classes = useStyles();

    const getPreviewTags = useCallback(
        (
            goalIds: string[],
            packIds: string[],
            channelIds: string[],
            tags: string[]
        ): string[] => {
            const previewTags: string[] = [];
            goalIds.forEach(goalId => {
                const goalName = filtersData.goalIds.find(
                    goal => goal.id === goalId
                )?.translation;
                previewTags.push(`Goal: ${goalName}`);
            });
            (packIds || []).forEach(packId => {
                const packName = filtersData.packIds.find(
                    pack => pack.id === packId
                )?.translation;
                previewTags.push(`Pack: ${packName}`);
            });
            (channelIds || []).forEach(channelId => {
                const channelName = filtersData.channelIds.find(
                    channel => channel.id === channelId
                )?.translation;
                previewTags.push(`Channel: ${channelName}`);
            });
            (tags || []).forEach(tag => {
                previewTags.push(tag);
            });
            return previewTags;
        },
        [filtersData]
    );

    useEffect(() => {
        setIsChangingTags(true);
        if (filtersData.goalIds.length) {
            const previewTags = getPreviewTags(
                template.goalIds,
                template.packIds || [],
                template.channelIds || [],
                template.tags || []
            );
            if (
                (!template.previewTags && previewTags) ||
                (template.previewTags &&
                    template.previewTags.length !== previewTags.length)
            ) {
                onChangeTemplate({ ...template, previewTags });
            }
            setIsChangingTags(false);
        }
    }, [filtersData, getPreviewTags, onChangeTemplate, template]);

    const onTagsChange = (tags: string[]) => {
        const previewTags = getPreviewTags(
            template.goalIds,
            template.packIds || [],
            template.channelIds || [],
            tags
        );
        refreshTemplatePreviewTags(previewTags, 'tags', tags);
    };

    const onChangeGoal = (goalIds: string[]) => {
        const previewTags = getPreviewTags(
            goalIds,
            template.packIds || [],
            template.channelIds || [],
            template.tags || []
        );
        refreshTemplatePreviewTags(previewTags, 'goalIds', goalIds);
    };

    const onChangePack = (packIds: string[]) => {
        const previewTags = getPreviewTags(
            template.goalIds,
            packIds || [],
            template.channelIds || [],
            template.tags || []
        );
        refreshTemplatePreviewTags(previewTags, 'packIds', packIds);
    };

    const onChangeChannel = (channelIds: string[]) => {
        const previewTags = getPreviewTags(
            template.goalIds,
            template.packIds || [],
            channelIds || [],
            template.tags || []
        );
        refreshTemplatePreviewTags(previewTags, 'channelIds', channelIds);
    };

    const refreshTemplatePreviewTags = (
        previewTags: string[],
        propertyName: string,
        propertyValue: string[]
    ) => {
        setIsChangingTags(true);
        onChangeTemplate({
            ...template,
            [propertyName]: propertyValue,
            previewTags
        });
        setTimeout(() => {
            setIsChangingTags(false);
        }, 0);
    };

    return (
        <Grid container spacing={4}>
            <Grid item xs={8} container spacing={4}>
                <Grid item xs={12} container spacing={2}>
                    <Grid item md={12}>
                        <Typography variant='h6'>
                            1. Establish the template&apos;s attributes
                        </Typography>
                    </Grid>
                    <Grid item md={7}>
                        <TextField
                            value={template.name}
                            hiddenLabel
                            variant='filled'
                            size='small'
                            required
                            fullWidth
                            onChange={e =>
                                onChangeTemplate({
                                    ...template,
                                    name: e.target.value
                                })
                            }
                            inputProps={{ maxLength: 60 }}
                            placeholder='Enter the name'
                        />
                    </Grid>
                    <Grid item md={7}>
                        <TextField
                            value={template.description}
                            hiddenLabel
                            variant='filled'
                            size='small'
                            multiline
                            rows={4}
                            required
                            fullWidth
                            onChange={e =>
                                onChangeTemplate({
                                    ...template,
                                    description: e.target.value
                                })
                            }
                            inputProps={{ maxLength: 210 }}
                            placeholder='Enter the description'
                        />
                    </Grid>
                    <Grid item md={7}>
                        <TextField
                            value={template.knowledgeCenterUrl}
                            hiddenLabel
                            variant='filled'
                            size='small'
                            required
                            fullWidth
                            onChange={e =>
                                onChangeTemplate({
                                    ...template,
                                    knowledgeCenterUrl: e.target.value
                                })
                            }
                            inputProps={{ maxLength: 1000 }}
                            placeholder='Enter the link to Guide article'
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant='h6'>
                            2. Upload the template
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TemplateUpload
                            onTemplateSelected={({
                                name,
                                description,
                                thumbnailImageUrl,
                                token
                            }) =>
                                onChangeTemplate({
                                    ...template,
                                    name: template.name || name,
                                    description:
                                        template.description || description,
                                    thumbnailImageUrl,
                                    token
                                })
                            }
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant='h6'>
                            3. Establish filters
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography component='span' className={classes.label}>
                            Language:
                        </Typography>
                        <ToggleButtonGroup
                            color='primary'
                            value={template.cultureCode}
                            exclusive
                            onChange={(e, cultureCode) =>
                                cultureCode &&
                                onChangeTemplate({ ...template, cultureCode })
                            }
                        >
                            <ToggleButton value='es'>Spanish</ToggleButton>
                            <ToggleButton value='en'>English</ToggleButton>
                        </ToggleButtonGroup>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography component='span' className={classes.label}>
                            Level:
                        </Typography>
                        <ToggleButtonGroup
                            color='primary'
                            value={template.levelId}
                            exclusive
                            onChange={(e, levelId) =>
                                levelId &&
                                onChangeTemplate({ ...template, levelId })
                            }
                        >
                            {filtersData.levelIds.length &&
                                filtersData.levelIds.map((level, index) => (
                                    <ToggleButton
                                        style={{
                                            textTransform: 'capitalize'
                                        }}
                                        key={index}
                                        value={level.id}
                                    >
                                        {level.translation}
                                    </ToggleButton>
                                ))}
                        </ToggleButtonGroup>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography component='span' className={classes.label}>
                            Goal:
                        </Typography>
                        <ToggleButtonGroup
                            color='primary'
                            value={template.goalIds}
                            onChange={(e, goalIds) =>
                                goalIds &&
                                goalIds.length &&
                                onChangeGoal(goalIds)
                            }
                        >
                            {filtersData.goalIds.length &&
                                filtersData.goalIds.map((goalId, index) => (
                                    <ToggleButton
                                        style={{
                                            textTransform: 'capitalize'
                                        }}
                                        key={index}
                                        value={goalId.id}
                                    >
                                        {goalId.translation}
                                    </ToggleButton>
                                ))}
                        </ToggleButtonGroup>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography component='span' className={classes.label}>
                            Pack:
                        </Typography>
                        <ToggleButtonGroup
                            color='primary'
                            value={template.packIds}
                            onChange={(e, packIds) => onChangePack(packIds)}
                        >
                            {filtersData.packIds.length &&
                                filtersData.packIds.map((packId, index) => (
                                    <ToggleButton
                                        style={{
                                            textTransform: 'capitalize'
                                        }}
                                        key={index}
                                        value={packId.id}
                                    >
                                        {packId.translation}
                                    </ToggleButton>
                                ))}
                        </ToggleButtonGroup>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography component='span' className={classes.label}>
                            Channel:
                        </Typography>
                        <ToggleButtonGroup
                            color='primary'
                            value={template.channelIds}
                            onChange={(e, channelIds) =>
                                onChangeChannel(channelIds)
                            }
                        >
                            {filtersData.channelIds.length &&
                                filtersData.channelIds.map(
                                    (channelId, index) => (
                                        <ToggleButton
                                            style={{
                                                textTransform: 'capitalize'
                                            }}
                                            key={index}
                                            value={channelId.id}
                                        >
                                            {channelId.translation}
                                        </ToggleButton>
                                    )
                                )}
                        </ToggleButtonGroup>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={4}>
                <Typography variant='h6'>Template preview</Typography>
                {!isChangingTags && (
                    <TemplateCard
                        template={{
                            ...template,
                            id: '',
                            creationDate: new Date(),
                            tags: template.previewTags
                        }}
                        disabled
                    />
                )}
            </Grid>
            <Grid item xs={12}>
                <Typography variant='h6'>4. Insert tags</Typography>
            </Grid>
            <Grid item xs={12}>
                <ChipsSelector
                    readonly={false}
                    onChipsChange={onTagsChange}
                    columnsInput={3}
                    chips={template.tags ? template.tags : []}
                    suggestedChips={filtersData.tags}
                ></ChipsSelector>
            </Grid>
            <Grid item xs={12}>
                <Typography variant='h6'>5. Establish priority</Typography>
            </Grid>
            <Grid item xs={1} sx={{ marginBottom: '20px' }}>
                <NumberField
                    placeholder='0-999'
                    value={template.priority}
                    maxLength={3}
                    isError={isPriorityError}
                    onChange={(event, value) => {
                        setIsPriorityError(
                            !!value && (value < 0 || value > 999)
                        );
                        onChangeTemplate({
                            ...template,
                            priority: value as number
                        });
                    }}
                />
            </Grid>
        </Grid>
    );
}

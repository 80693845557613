import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { WorkflowTemplate } from '../../models/WorkflowTemplate';
import TemplateCard from './TemplateCard';
import PublishTemplateConfirmationDialog from './PublishTemplateConfirmationDialog';
import DeleteTemplateConfirmationDialog, {
    WorkflowTemplateDeleteConfirmation
} from './DeleteTemplateConfirmationDialog';

type Props = {
    templates: WorkflowTemplate[];
    searchText?: string;
    onDelete: (templateId: string) => void;
    setPublished: (template: string, value: boolean) => void;
};

export default function TemplateListItems({
    templates,
    searchText,
    onDelete,
    setPublished
}: Props): JSX.Element {
    const [confirmDelete, setConfirmDelete] =
        useState<WorkflowTemplateDeleteConfirmation>();
    const [confirmPublish, setConfirmPublish] = useState<string>();
    const [confirmPublishValue, setConfirmPublishValue] =
        useState<boolean>(false);

    const setPublishedTemp = (templateId: string, value: boolean) => {
        setConfirmPublish(templateId);
        setConfirmPublishValue(value);
    };

    const templateToBeDeleted = templates.find(t => t.id === confirmDelete?.id);
    const templateToBeSetPublish = templates.find(t => t.id === confirmPublish);

    return (
        <Grid container spacing={2}>
            {templates.map(template => (
                <Grid item key={template.id}>
                    <TemplateCard
                        template={template}
                        onDelete={setConfirmDelete}
                        onSetPublish={setPublishedTemp}
                        searchText={searchText}
                    />
                </Grid>
            ))}
            {confirmDelete && templateToBeDeleted && (
                <DeleteTemplateConfirmationDialog
                    open={true}
                    template={templateToBeDeleted}
                    onClose={() => setConfirmDelete(undefined)}
                    onDelete={template => {
                        onDelete(template.id);
                        setConfirmDelete(undefined);
                    }}
                />
            )}
            {confirmPublish && templateToBeSetPublish && (
                <PublishTemplateConfirmationDialog
                    open={true}
                    template={templateToBeSetPublish}
                    isPublish={confirmPublishValue}
                    onClose={() => setConfirmPublish(undefined)}
                    onSetPublish={(template, value) => {
                        setPublished(templateToBeSetPublish.id, value);
                        setConfirmPublish(undefined);
                    }}
                />
            )}
        </Grid>
    );
}
